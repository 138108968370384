import React from 'react'
import './schedule.css'
import Timeline from '../../components/Schedule/Timeline'
export const Schedulepage = () => {
  return (
    <div className='top'>
      <div className=''>
        <Timeline/>
      </div>
    </div>
  )
}
